import React from 'react'

import { Flex, Box } from '@rebass/grid/emotion'
import { useStaticQuery,  graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import ProductGrid from '../components/ProductGrid'

const IndexPage = ({products}) => {
  const data = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: {
            fields: [createdAt]
            order: DESC
          }
        ) {
          edges {
            node {
              id
              title
              handle
              productType
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Box px={2}>
        <p>We exist to make gifting a little more fun ;)</p>
        <ul>
          <li><h4>Full Service</h4>That means you dont need to worry about a thing.</li>
          <li><h4>Limited Edition</h4>Only 10 available per gift.</li>
          <li><h4>One of a Kind</h4>These gifts are a production bringing together unique elements for the very first time.</li>
        </ul>
      </Box>
      <Box p={2}>
        <h3>Our Latest Gifts</h3>
      </Box>
      <ProductGrid products={data.allShopifyProduct.edges}/>
    </>
  );
}

export default IndexPage
