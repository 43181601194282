import React from 'react'
import { groupBy } from 'lodash'
import { useStaticQuery,  graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'

import { Img } from '../../utils/styles'

import ProductItem from './ProductItem'

const ProductGrid = ({products}) => {

  return (
    <Flex flexWrap='wrap'>
      {Object.entries(groupBy(products, 'node.productType')).map(([group, products]) => (
        <Box width={1}>
          <div>
          {products.map(product => (
            <ProductItem product={product.node} />
          ))}
          </div>
        </Box>
      ))}
    </Flex>
  )
}

export default ProductGrid
